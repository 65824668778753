var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "btn-container flex f-ai-c f-jc-c" }, [
    _c("button", { on: { click: _vm.clickHandle } }, [
      _vm._v(_vm._s(_vm.btnText)),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }